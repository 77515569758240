import React from "react"
import Layout from "../components/layout"
import Page from "../components/page"
import Form from "../components/form"
import Seo from "../components/seo"
import useContentfulImage from "../components/use-contentful-image"
import { graphql, Link } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export const query = graphql`
    query ($slug: String!) {
       post: contentfulBlogPost(slug: {eq: $slug}) {
            content {
                raw
            }
            heroImage {
                file {
                    url
                }
                title
                description
            }
            slug
            title
            description {
                description
            }
            publishDate(formatString: "DD MMM YYYY")
        }
    }
`;

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
        const asset = useContentfulImage(node.data.target.sys.id)
            if (asset) {
                return (
                    <img src={asset.node.file.url} alt={asset.node.title} width={asset.node.file.details.image.width} height={asset.node.file.details.image.height} />
                )
            }
        },
    },
}

function Post({ data: { post } }) {
    return (
        <Layout>
            <Seo
                title={post.title}
                description={post.description.description}
            />

            <Page pageTitle={post.title} pageClass="page-post">
                <p className="post-meta">Posted on: <strong>{post.publishDate}</strong></p>
                <p className="post-img">
                    <img src={post.heroImage.file.url} alt={post.heroImage.title} />
                    <small>{post.heroImage.description}</small>
                </p>
                <div className="post-content">
                    {renderRichText(post.content, options)}
                    <br />
                    <p><Link to="/blog">&larr; Back to all posts</Link></p>
                </div>
            </Page>

            <Form />
        </Layout>
    );
}

export default Post
